import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BottomBar } from '../components/BottomBar';
// @ts-ignore
import logo from '../assets/logo.svg';
import { Container } from '../components/Container';

type NotFoundPageData = {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
};

export default function NotFound({ data }: PageProps<NotFoundPageData>) {
  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
      </Helmet>
      <div className="h-screen flex flex-col justify-between">
        <Container className="flex flex-col md:flex-row flex-1 justify-center items-center h-100">
          <img
            src={logo}
            alt="Bitmade Logo"
            className="block w-24 mb-4 flex-shrink-0 md:mr-4 md:mb-0"
          />
          <h1 className="text-lg text-center md:text-4xl md:text-left">
            <strong className="text-2xl md:text-4xl">404</strong>
            <br />
            Diese Seite haben wir leider nicht gefunden
          </h1>
        </Container>

        <BottomBar />
      </div>
    </>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
